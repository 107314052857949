.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#total-card {
  background-color: #cce5ff;
}

#low-card {
  background-color: #fff3cd;
}

#zero-card {
  background-color: #f8d7da;
}

.product-card {
  transition: .8s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.landing {
  background-color: rgb(255, 254, 254);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 8px;
}


.page {
  overflow: scroll;
  background: linear-gradient(-45deg, #6c95ce, #a4caff, rgb(13, 157, 210), #075cc5);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  }
  
  @keyframes gradient {
  0% {
  background-position: 0% 50%;
  }
  
  50% {
  background-position: 100% 50%;
  }
  
  100% {
  background-position: 0% 50%;
  }
  }


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
